import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { apidata } from "./apidata"; 
import { formApi } from "./formApi";

export const store = configureStore({
  reducer: {
    [apidata.reducerPath]: apidata.reducer, 
    [formApi.reducerPath]: formApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apidata.middleware, formApi.middleware), 
});

setupListeners(store.dispatch);
