import React from 'react';
import { Link } from 'react-router-dom';
import './indexcss/Featured.css';
import Loader from '../../Components/Loader'
import { Swiper, SwiperSlide } from 'swiper/react';
import { IoIosStar } from "react-icons/io";
import { ImLocation } from "react-icons/im";
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import { useGetPackageQuery } from '../../Apihandlers/apidata';
import { useGetLocsQuery } from '../../Apihandlers/apidata';
const Kanatalfeatured = () => {
  const { data: pkgdataCard, isError: pkgcardError, isLoading: pkgCardLoading } = useGetPackageQuery();
  const { data: pkglok, isError: pkglokError, isLoading: pkglokLoading } = useGetLocsQuery();
  if (pkgcardError) {
    return <div>Error loading data...</div>;
  }

  if (pkgCardLoading) {
    return <div> </div>;
  }
  
  let feturedData = [];


  pkgdataCard.forEach((p) => {
    pkglok.forEach((l) => {
      if (p.resort_camp_data.location_id === l.location.id) {
    
        const newItem = {
          "heading": p.resort_camp_data.heading,
          "location": l.location.location,
          "pkgLink": p.resort_camp_data.package_url,
          "small_description": p.resort_camp_data.small_description,
          "price": p.resort_camp_data.price,
          "banner_image": p.banner_image,
          "F_status": p.resort_camp_data.F_status,
          "rating": p.resort_camp_data.rating,
          "nightDay": p.resort_camp_data.nightDay
        };
        const exists = feturedData.some(item => item.pkgLink === newItem.pkgLink);
        if (!exists) {
          feturedData.push(newItem);
        }
      }
    });
  });

  
  return (
    <section className='Featured-section'>
      <div className='featured-text'>
        <p>Quench Your</p>
        <h2> Featured Properties</h2>
        <h3>Grab the exclusive deals & make countless memories with your family on your Weekend Getaways at Luxury Resorts </h3>
      </div>
      <div className='featured-property-conatiner'>
        <Swiper
            
            spaceBetween={40}
            loop={true}
            speed={1300}
            navigation={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              500: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
             
              988: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1260: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          modules={[Autoplay, Navigation]}
          className="mySwiper Featured"
        >
          {feturedData.map((itm, key) => {
            if (itm.F_status === 1) {
              return (
                <SwiperSlide key={key}>
                  <div className='featured-property'>
                  <a href={itm.pkgLink}>
                    <div className='property-image'>
                      <img src={itm.banner_image.map((b)=>b.banner)} alt='Resort Image' />
                      <div className='rating'  style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}}>
                      {Array.from({ length: itm.rating }, (_, index) => (
                          <IoIosStar key={index} className='star-rating' />
                      ))}

                      </div>
                    </div>
                    <div className='property-text'>
                      <div className='topline'><h4 
                      style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}}
                      >{itm.heading} </h4><span className='ftdlok'><ImLocation /> {itm.location}</span></div>
                      <p>{itm.small_description}</p>
                      <div className='price-link'>
                        <div className='price-feature'>
                        <h5>{itm.price ? `INR ${itm.price}/-**` : 'Call for Price'}</h5>
                        <span>{itm.nightDay ?? ''}</span>
                        </div>
                        <a href={itm.pkgLink}>View More</a>
                      </div>
                    </div>
                  </a>
                  </div>
                </SwiperSlide>
              );
            }
            return null; // Ensure to handle other cases, if needed
          })}
        </Swiper>
      </div>
    </section>
  );
}

export default Kanatalfeatured;
