import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const formApi = createApi({
  reducerPath: 'formApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://leads.hotelstourandtravel.com/api/'
  }),
  endpoints: (builder) => ({
    postLeads: builder.mutation({
      query: (formDatas) => ({
        url: 'cyj-leads-insert',
        method: 'POST',
        body: formDatas
      })
    })
  })
});

export const { usePostLeadsMutation } = formApi;
