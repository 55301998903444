import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import './indexcss/Indextestandform.css'
import { usePostLeadsMutation } from '../../Apihandlers/formApi';
function Indexform() {
    const [postLeads, { isLoading, isError, isSuccess }] = usePostLeadsMutation();
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    const links = window.location.href;
    
    


    const [formData, setFormData]=useState({
      name: '',
      mobile: '',
      email: '',
      message: '',
      links: links
    });

   

const handleChange = (e)=>{
    setFormData({...formData, [e.target.name]: e.target.value});
}


const website_name = 'Kanatal Resorts';
const indiaDateTime = new Date().toLocaleString('en-IN', {
  timeZone: 'Asia/Kolkata',
  hour12: false,            
  weekday: 'long',         
  year: 'numeric',          
  month: 'long',        
  day: 'numeric',       
  hour: '2-digit',         
  minute: '2-digit',       
  second: '2-digit'   
});

const formattedDateTime = indiaDateTime.replace(/,/g, ''); 

let contact = '';
if (formData['name'] !== '') contact += `Name:-${formData['name']}, `;
if (formData['mobile'] !== '') contact += `Mobile:-${formData['mobile']}, `;
if (formData['email'] !== '') contact += `Email:-${formData['email']}, `;
if (formData['links'] !== '') contact += `Url:-${formData['links']}, `;
contact += `Date:-${formattedDateTime}`;
const messages = formData['message'];

const formdta = {
  website_name: website_name,
  contact: contact,
  message: messages
};

// Handle form submission
const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage();
    setErrors({});

    try {
      await postLeads(formdta).unwrap();
      
      setMessage('Your message has been sent successfully!');
      setFormData({
        name: '',
        mobile: '',
        email: '',
        message: '',
        links: links
      });
    } catch (error) {

      setMessage(error.message);
      setErrors(error.data || {});
      }
  };
 
  return (
    <>

    <section className='test-form-section'>

{/* testimonial */}

<div className='testimonial-section'>
  <div className='test-text'>
    <p>What</p>
    <h2>They Say</h2>
  </div>

<Swiper
            slidesPerView= {1}
            spaceBetween={40}
            loop={true}
            speed={1300}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
           
          modules={[Autoplay , Pagination]}
          className="mySwiper testimonial"
        >
          <SwiperSlide> <div className='testimonial-slide'>
            <p>One of our common friends suggested us for CYJ and we planned to opt for the same and went to Mussoorie. They made our stay happy and beautiful with their impeccable services.</p>
            <h4>Krishna Sharma</h4>
          </div>
          </SwiperSlide>
          <SwiperSlide> <div className='testimonial-slide'>
            <p>I went to Kanatal last year with my friends and hands down! Booking a tour package from CYJ was the best thing that I could do! I would highly recommend everyone opt for CYJ.</p>
            <h4>Aman Madaan</h4>
          </div>
          </SwiperSlide>
          <SwiperSlide> <div className='testimonial-slide'>
            <p>We had been to Rishikesh last month with my family and had rafting package with CYJ and hopefully it was my best decision as they made it a hassle-free experience.</p>
            <h4>Uttam Yadav</h4>
          </div>
          </SwiperSlide>
          
   </Swiper>
</div>


{/* form start */}

<div class="index-form">

        {/* <p>Fill the Form to Send your Request</p> */}
       

    <div class="star"></div>
    {/* <div class="star"></div> */}
    <div class="star"></div>
    {/* <div class="star"></div> */}
    <div class="star"></div>

  
    <div class="contact-form">
    <h2>Get in Touch</h2>
       <form onSubmit={handleSubmit} >
       <img src='./images/formimage.png' className='form-image' alt='kanatal resorts'></img>
      <input
        type="text"
        name="name"
        placeholder="Your Name"
        value={formData.name} onChange={handleChange}
      />
      <span style={{color:'red', fontSize:'.8rem',}}>{errors.name}</span>
      
      <input
        type="number"
        name="mobile"
        placeholder="Your Number"
        value={formData.mobile} onChange={handleChange}
      />
            <span style={{color:'red', fontSize:'.8rem',}}>{errors.mobile}</span>
      
      <input
        type="email"
        name="email"
        placeholder="Your Email"
        value={formData.email} onChange={handleChange}
      />
            <span style={{color:'red', fontSize:'.8rem',}}>{errors.email}</span>
      <textarea
        name="message"
        placeholder="Message"
        value={formData.message} onChange={handleChange}
      />

      <button
              type="submit"
              id="submit"
              name="submit"
              className="form-btn semibold"
              disabled={isLoading}
            >
              {isLoading ? 'Sending...' : 'Send Enquiry'}
          </button>
          {message && <div className={`alert ${isSuccess ? 'success' : 'error'}`}>{message}</div>}
    </form>
        
     
      
    </div>

</div>

</section>

    </>
  )
}

export default Indexform
